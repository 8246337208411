.login-container {
    max-width: 400px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .login-container h2 {
    text-align: center;
    color: var(--primary-color);
    margin-bottom: 1.5rem;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: var(--text-color);
  }
  
  .form-group input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .login-button {
    width: 100%;
    padding: 0.75rem;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .login-button:hover {
    background-color: darken(var(--primary-color), 10%);
  }
  
  .error-message {
    color: #d32f2f;
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .separator {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 1rem 0;
  }
  
  .separator::before,
  .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #ddd;
  }
  
  .separator span {
    padding: 0 0.5rem;
    color: #6c757d;
  }
  
  .google-sign-in-button {
    width: 100%;
    padding: 0.75rem;
    background-color: #ffffff;
    color: #757575;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .google-sign-in-button:hover {
    background-color: #f8f9fa;
  }
  
  .google-sign-in-button svg {
    margin-right: 0.5rem;
    font-size: 1.2rem;
  }
  
  .register-link {
    text-align: center;
    margin-top: 1rem;
  }
  
  .register-link a {
    color: var(--primary-color);
    text-decoration: none;
  }
  
  .register-link a:hover {
    text-decoration: underline;
  }
  