@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

:root {
  --primary-color: #4a90e2;
  --secondary-color: #50c878;
  --background-color: #f8f9fa;
  --text-color: #333;
  --light-text-color: #6c757d;
}

body {
  font-family: 'Poppins', sans-serif;
  line-height: 1.6;
  color: var(--text-color);
  background-color: var(--background-color);
  margin: 0;
  padding: 0;
}

.landing-page {
  max-width: 1200px;
  margin: 0 auto;
}

header {
  background-color: rgba(255, 255, 255, 0.95);
  padding: 1rem;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: all 0.3s ease;
}

header.scrolled {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--primary-color);
}

nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  margin-left: 2rem;
}

nav ul li a {
  text-decoration: none;
  color: var(--text-color);
  font-weight: 500;
  transition: color 0.3s ease;
}

nav ul li a:hover {
  color: var(--primary-color);
}

.hero {
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  color: white;
  text-align: center;
  padding: 8rem 2rem;
  position: relative;
}

.hero h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  font-weight: 700;
}

.hero p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.cta-button {
  background-color: white;
  color: var(--primary-color);
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

.cta-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.scroll-indicator {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.scroll-indicator:hover {
  opacity: 1;
}

section {
  padding: 6rem 2rem;
}

h2 {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 3rem;
  color: var(--primary-color);
}

.feature-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.feature-item {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.feature-item:hover {
  transform: translateY(-5px);
}

.feature-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.feature-item h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: var(--primary-color);
}

.steps-container {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
}

.step {
  text-align: center;
  flex: 1;
  position: relative;
}

.step:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 25px;
  right: -50%;
  width: 100%;
  height: 2px;
  background-color: var(--primary-color);
}

.step-number {
  background-color: var(--primary-color);
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0 auto 1rem;
}

.pricing-container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 3rem;
}

.pricing-plan {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  flex: 1;
  max-width: 300px;
  text-align: center;
}

.pricing-plan.featured {
  transform: scale(1.05);
  border: 2px solid var(--primary-color);
}

.pricing-plan h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--primary-color);
}

.price {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.price span {
  font-size: 1rem;
  font-weight: normal;
  color: var(--light-text-color);
}

.pricing-plan ul {
  list-style: none;
  padding: 0;
  margin-bottom: 2rem;
}

.pricing-plan li {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.plan-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 50px;
  width: 100%;
}

.plan-button:hover {
  background-color: darken(var(--primary-color), 10%);
}

form {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: 0 auto;
}

form input,
form textarea {
  margin-bottom: 1rem;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.submit-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 50px;
}

.submit-button:hover {
  background-color: darken(var(--primary-color), 10%);
  transform: translateY(-2px);
}

footer {
  background-color: #333;
  color: white;
  padding: 3rem 2rem;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-logo {
  font-size: 1.5rem;
  font-weight: bold;
}

.footer-links {
  display: flex;
  gap: 2rem;
}

.footer-links a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: var(--secondary-color);
}

.social-links {
  display: flex;
  gap: 1rem;
}

.social-links a {
  color: white;
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: var(--secondary-color);
}

footer p {
  text-align: center;
  margin-top: 2rem;
  color: var(--light-text-color);
}

@media (max-width: 768px) {
  .hero h1 {
    font-size: 2.5rem;
  }

  .steps-container {
    flex-direction: column;
    gap: 2rem;
  }

  .step:not(:last-child)::after {
    display: none;
  }

  .pricing-container {
    flex-direction: column;
    align-items: center;
  }

  .pricing-plan {
    max-width: 100%;
  }

  .footer-content {
    flex-direction: column;
    gap: 2rem;
  }
}
.additional-info {
  margin-top: 3rem;
  text-align: left;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.additional-info h3 {
  color: var(--primary-color);
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}

.audience-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
}

.audience-item {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.audience-item:hover {
  transform: translateY(-5px);
}

.audience-item h3 {
  color: var(--primary-color);
  margin-bottom: 0.5rem;
}

@media (max-width: 768px) {
  .audience-grid {
    grid-template-columns: 1fr;
  }
}

.contact-info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.email-link {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color: var(--primary-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

.email-link:hover {
  color: var(--secondary-color);
}

.email-link svg {
  margin-right: 0.5rem;
}

.login-button {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: var(--secondary-color);
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: darken(var(--secondary-color), 10%);
}

.login-button svg {
  margin-right: 0.5rem;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
}

nav ul {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  margin-left: 2rem;
}

.login-button {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: var(--primary-color);
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: darken(var(--primary-color), 10%);
}

.login-button svg {
  margin-right: 0.5rem;
}

/* Mobile menu styles */
.mobile-menu-button {
  display: none;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

@media (max-width: 768px) {
  nav ul {
    display: none;
  }

  .mobile-menu-button {
    display: block;
  }

  .mobile-menu-open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  }

  .mobile-menu-open li {
    margin: 0;
    padding: 1rem;
    border-bottom: 1px solid #eee;
  }

  .mobile-menu-open .login-button {
    margin: 1rem;
  }
}
