.dashboard-container {
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .dashboard-container h1 {
    color: var(--primary-color);
    margin-bottom: 1rem;
  }
  
  .construction-message {
    background-color: #fffbd6;
    border-left: 4px solid #ffd700;
    padding: 1rem;
    margin: 1rem 0;
    text-align: left;
  }
  
  .fun-facts {
    background-color: #e8f5e9;
    border-radius: 8px;
    padding: 1rem;
    margin: 2rem 0;
    text-align: left;
  }
  
  .fun-facts h2 {
    color: var(--primary-color);
    margin-bottom: 1rem;
  }
  
  .fun-facts ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .fun-facts li {
    margin-bottom: 0.5rem;
  }
  
  .support-message {
    margin-top: 2rem;
  }
  
  .support-button {
    background-color: var(--secondary-color);
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 1rem;
  }
  
  .support-button:hover {
    background-color: darken(var(--secondary-color), 10%);
  }
  
  /* Mailchimp form styles */
  #mc_embed_signup {
    background: #fff;
    clear: left;
    font: 14px Helvetica, Arial, sans-serif;
    width: 100%;
    max-width: 600px;
    margin: 2rem auto;
  }
  
  #mc_embed_signup form {
    padding: 10px 0 10px 0;
  }
  
  #mc_embed_signup h2 {
    font-weight: bold;
    padding: 0;
    margin: 15px 0;
    font-size: 1.4em;
  }
  
  #mc_embed_signup input {
    border: 1px solid #ABB0B2;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  }
  
  #mc_embed_signup input[type=checkbox] {
    -webkit-appearance: checkbox;
  }
  
  #mc_embed_signup input[type=radio] {
    -webkit-appearance: radio;
  }
  
  #mc_embed_signup input:focus {
    border-color: #333;
  }
  
  #mc_embed_signup .button {
    clear: both;
    background-color: var(--primary-color);
    border: 0 none;
    border-radius: 4px;
    transition: all 0.23s ease-in-out 0s;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    font-weight: normal;
    height: 32px;
    line-height: 32px;
    margin: 0 5px 10px 0;
    padding: 0 22px;
    text-align: center;
    text-decoration: none;
    vertical-align: top;
    white-space: nowrap;
    width: auto;
  }
  
  #mc_embed_signup .button:hover {
    background-color: darken(var(--primary-color), 10%);
  }
  
  #mc_embed_signup .mc-field-group {
    clear: left;
    position: relative;
    width: 96%;
    padding-bottom: 3%;
    min-height: 50px;
  }
  
  #mc_embed_signup .mc-field-group label {
    display: block;
    margin-bottom: 3px;
  }
  
  #mc_embed_signup .mc-field-group input {
    display: block;
    width: 100%;
    padding: 8px 0;
    text-indent: 2%;
  }
  
  #mc_embed_signup .indicates-required {
    text-align: right;
    font-size: 11px;
    margin-right: 4%;
  }
  
  #mc_embed_signup .asterisk {
    color: #e85c41;
    font-size: 150%;
    font-weight: normal;
    position: relative;
    top: 5px;
  }
  
  .support-message {
    margin-top: 2rem;
    text-align: center;
  }
  
  .support-message p {
    margin-bottom: 1rem;
  }
  
  .support-button {
    display: inline-block;
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-decoration: none;
  }
  
  .support-button:hover {
    background-color: #45a049;
  }